// An error code will be four digit number e.g. 1101
// The first digit 1 will correspond with an error code from a process
// The second digit will related to the event type e.g MRTB
// The third/fourth digit will be related to the specific error itself i.e. ODBMS being down

// Status change error codes
export const DEPRECATED_SIZES_SELECTED_PROPOSED_ERROR = '1001'

// Generic something went wrong
export const MARK_READY_TO_BUY_GENERIC_ERROR = '1101'
// Call to ORDS failed
export const MARK_READY_TO_BUY_ORDS_ERROR = '1102'
export const MARK_READY_TO_BUY_DEPRECATED_SIZES_ERROR = '1103'

export const SIZE_AFTER_READY_TO_BUY_GENERIC_ERROR = '1201'
export const SIZE_AFTER_READY_TO_BUY_ORDS_ERROR = '1202'
export const SIZE_AFTER_READY_TO_BUY_COMMITMENT_ERROR = '1203'

export const MARK_CANCELLED_SVP_ERROR = '1301'
export const MARK_CANCELLED_ODBMS_ERROR = '1302'
export const MARK_CANCELLED_SKU_IN_USE_ERROR = '1303'
export const MARK_CANCELLED_ODBMS_AND_ODBMS_ERROR = '1304'
export const MARK_CANCELLED_ODBMS_AND_SKU_IN_USE_ERROR = '1305'
export const MARK_CANCELLED_SKU_IN_USE_AND_ODBMS_ERROR = '1306'
export const MARK_CANCELLED_SKU_IN_USE_SKU_IN_USE_ERROR = '1307'

export const MARK_READY_TO_BUY_PRODUCT_ERROR_TYPE = 'mark-as-rtb'
export const SIZE_AFTER_READY_TO_BUY_PRODUCT_ERROR_TYPE = 'sizes-after-rtb'
export const MARK_CANCELLED_ERROR_TYPE = 'mark-as-cancelled'

// Wholesale error codes
// UPLOAD endpoint
export const ERROR_CODE_UPLOAD_REQUEST_BODY = '1401'
export const ERROR_CODE_UPLOAD_MIME_TYPE = '1402'
export const ERROR_CODE_UPLOAD_MIN_SIZE = '1403'
export const ERROR_CODE_UPLOAD_MAX_SIZE = '1404'
export const ERROR_CODE_UPLOAD_PNG_TO_JPEG_CONVERT = '1405'
export const ERROR_CODE_UPLOAD_CREATE_THUMBNAIL = '1406'
export const ERROR_CODE_UPLOAD_UPSERT = '1407'
export const ERROR_CODE_UPLOAD_UPLOADING_TO_S3 = '1408'

// DELETE endpoint
export const ERROR_CODE_DELETE_REQUEST = '1501'
export const ERROR_CODE_DELETE_SVP = '1502'
export const ERROR_CODE_DELETE_LAST_IMAGE_OF_WS_READY_PRODUCT = '1503'
export const ERROR_CODE_DELETE_FROM_DB = '1504'

// REPLACE endpoint
export const ERROR_CODE_REPLACE_REQUEST_PATH = '1601'
export const ERROR_CODE_REPLACE_REQUEST_SVP = '1602'
export const ERROR_CODE_REPLACE_REQUEST_BODY = '1603'
export const ERROR_CODE_REPLACE_MIME_TYPE = '1604'
export const ERROR_CODE_REPLACE_MIN_SIZE = '1605'
export const ERROR_CODE_REPLACE_MAX_SIZE = '1606'
export const ERROR_CODE_REPLACE_PNG_TO_JPG_CONVERT = '1607'
export const ERROR_CODE_REPLACE_CREATE_THUMBNAIL = '1608'
export const ERROR_CODE_REPLACE_REPLACING_IN_S3 = '1609'

export const WHOLESALE_IMAGES_SLOTS = 4
export const ERROR_CODE_FILE_TOO_LARGE = 'file-too-large'
export const ERROR_CODE_FILE_TOO_SMALL = 'file-too-small'
export const ERROR_CODE_FILE_INVALID_TYPE = 'file-invalid-type'
export const ERROR_CODE_FILES_TOO_MANY = 'files-too-many'
export const ERROR_MESSAGE_CORRUPTED_IMAGE =
  'Image upload failed. It seems that your image may be corrupted, please check the image file or try uploading a new image.'
export const ERROR_CODE_WHOLESALE_IMAGE_NOT_SET = '17'
export const ERROR_CODE_WHOLESALE_READY_WEEK_SELLING_PERIOD = '1104'
export const ERROR_CODE_WHOLESALE_READY_WEEK_NO_ACTIVE_SKUS = '1106'
export const ERROR_CODE_WHOLESALE_READY_WEEK_NO_SELLING_PRICE = '1108'
